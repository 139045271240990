import React from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { useRegistrationFormContext } from '../RegistrationForm/RegistrationForm';
import { useSiteContext } from '../../../../components/SiteContext/SiteContext';

import DeniedScreen from './DeniedScreen';
import CuentaOnly from './rtr/CuentaOnly';
import SecuredCard from './rtr/SecuredCard';
import Multiproduct from './rtr/Multiproduct';
import CuentaOnlyMagic from './rtr/magic-link/CuentaOnlyMagic';
import SecuredCardMagic from './rtr/magic-link/SecuredCardMagic';
import MultiproductMagic from './rtr/magic-link/MultiproductMagic';

const MAGIC_LINK_XP_ID = '344507';
const MAGIC_LINK_XP_VARIANT_1_ID = '46652';

const DecisionStepGoalsXp = () => {
  // Magic Link Experiment
  const magicLinkVariant = useFigPiiExperiment(MAGIC_LINK_XP_ID);

  const { email } = useSiteContext();
  const {
    rtrScreen,
    rtrAnalisisResult,
  } = useRegistrationFormContext();

  // TBD - ADD events

  return (
    <Form.Step isForm={false}>
      {({ formsValues }) => {
        if (rtrScreen === 'APPROVED') {
        /// ////////////////////////// CUENTA ONLY////////////////////////////////////////
          if (rtrAnalisisResult === 'savings_account') {
            if (magicLinkVariant === MAGIC_LINK_XP_VARIANT_1_ID) {
              return <CuentaOnlyMagic />;
            }

            return <CuentaOnly email={email} />;
          }

          /// ////////////////////////// SECURED CARD////////////////////////////////////////
          if (rtrAnalisisResult === 'secured_credit_card_and_nuconta') {
            if (magicLinkVariant === MAGIC_LINK_XP_VARIANT_1_ID) {
              return <SecuredCardMagic />;
            }

            return <SecuredCard email={email} />;
          }

          // ///////////////////////////// MULTIPRODUCT ////////////////////////////////////////
          if (magicLinkVariant === MAGIC_LINK_XP_VARIANT_1_ID) {
            return <MultiproductMagic />;
          }

          return <Multiproduct email={email} />;
        }

        /// ////////////////////////// DENIED/NEUTRAL ////////////////////////////////////////
        return (
          <DeniedScreen name={formsValues.names} email={email} />
        );
      }}
    </Form.Step>
  );
};

export default DecisionStepGoalsXp;
