import React from 'react';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import { RegistrationForm } from './patterns/RegistrationForm/RegistrationForm';
import DecisionStep from './patterns/DecisionStep/DecisionStep';
import ZipAddressStepWithSearch from './patterns/ZipAddressStep/ZipAddressStepWithSearch';
import ContactDataStep from './patterns/ContactDataStep/ContactDataStep';
import BuroValidationOTP from './patterns/BuroValidationOTP/BuroValidationOTP';
import CurpFeedback from './patterns/PersonalInfoStep/FeedbackExperiment/CurpFeedback';
import FinancialGoals from './patterns/FinancialGoals/FinancialGoals';
import DecisionStepGoalsXp from './patterns/DecisionStep/DecisionStepGoalsXp';

const FINANCIAL_GOALS_EXPERIMENT_ID = '344539';
const FINANCIAL_GOALS_EXPERIMENT_VARIANT = '46713';

const RegistrationFormWithCURP = () => {
  const financialGoalsVariant = useFigPiiExperiment(FINANCIAL_GOALS_EXPERIMENT_ID);

  // Financial Goals Experiment
  if (financialGoalsVariant === FINANCIAL_GOALS_EXPERIMENT_VARIANT) {
    return (
      <RegistrationForm>
        <ContactDataStep />
        <CurpFeedback />
        <ZipAddressStepWithSearch shouldTriggerOTP />
        <BuroValidationOTP />
        <FinancialGoals />
        <DecisionStepGoalsXp />
      </RegistrationForm>
    );
  }

  // Original
  return (
    <RegistrationForm>
      <ContactDataStep />
      <CurpFeedback />
      <ZipAddressStepWithSearch shouldTriggerOTP />
      <BuroValidationOTP />
      <DecisionStep />
    </RegistrationForm>
  );
};

export default RegistrationFormWithCURP;
