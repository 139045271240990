import { isProduction } from '@nubank/www-latam-commons/utils/environmentUtils';

import { magicLinkEvent } from '../../screens/Registration/tracking';

export const DEFAULT_PROD_MAGIC_LINK = 'https://link.nubank.com.br/mx-magic-link-website';
export const DEFAULT_STAGING_MAGIC_LINK = 'https://nubank.test-app.link/1C6Emqet6Nb';

export function generateBranchLink({ prospectId }) {
  const baseLink = process.env.NODE_ENV === 'production' && isProduction() ? DEFAULT_PROD_MAGIC_LINK : DEFAULT_STAGING_MAGIC_LINK;
  magicLinkEvent(`${baseLink}?prospect_id=${prospectId}`);

  return `${baseLink}?prospect_id=${prospectId}`;
}

