export const useDynamicYieldValues = () => {
  const dynamicState = {
    values: {
      dynamicYield: '12.25',
      dynamicGATNominal: '13.03',
      dynamicGATReal: '8.89',
      // 7 DAYS
      dynamicYield7Days: '12.50',
      dynamicGATNominal7Days: '13.31',
      dynamicGATReal7Days: '9.16',
      // 28 DAYS
      dynamicYield28Days: '12.87',
      dynamicGATNominal28Days: '13.73',
      dynamicGATReal28Days: '9.57',
      // 90 days
      dynamicYield90Days: '14.25',
      dynamicGATNominal90Days: '15.31',
      dynamicGATReal90Days: '11.09',
      // 180 days
      dynamicYield180Days: '12.36',
      dynamicGATNominal180Days: '13.15',
      dynamicGATReal180Days: '9.01',
    },
    textTemplates: {
      VALUES_CALCULATED_AT: '15 de noviembre de 2024',
      MINIMUM_SAVINGS_AMOUNT: '$0.01',
      FROZEN_MINIMUM_SAVINGS_AMOUNT: '$50.00',
      VALID_UNTIL: '2 de enero de 2025',
    },
  };

  return dynamicState;
};

export const FROZEN_TIME = {
  A_180_DAYS: 180,
  A_90_DAYS: 90,
  A_28_DAYS: 28,
  A_7_DAYS: 7,
  NO_FROZEN: 0,
};
